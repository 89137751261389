html,
body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh !important;
  background-color: var(--background-dark) !important;
  color: white;
  font-size: 16px;
  font-family: "Raleway", Tahoma, Geneva, Verdana, sans-serif;
}

main {
  padding: 0 1rem 1rem 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h2 {
  color: var(--primary-color);
  font-weight: 900;
  font-size: 1.5rem;
}

hr {
  border: 1px solid var(--primary-color);
  margin: 1rem 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

a:hover {
  color: var(--hover-color) !important;
}

dd {
  margin: 0;
}

button.hollow {
  border: 0;
  background-color: transparent;
}

.wide {
  width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .wide-only {
    display: none;
  }
}

.icon-margins {
  margin: 0 0.5rem 0 0;
}
